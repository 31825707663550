// HP2.js

import React from 'react';

function HP2() {
  return (
    <div className="content">
      <h2 className="decorated">HP2 Mods</h2>
      <p>Well... There will be stuff here soon. Promise. I hope.</p>
    </div>
  );
}

export default HP2;
